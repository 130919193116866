<template>
  <footer class="site-footer">
    <p class="copyright text-xs">© ROOTS 2024. Design by SPECIFIC GENERIC.</p>
  </footer>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.site-footer {
  width: var(--inner-width);
  margin: auto;
  color: var(--color-brown);
  padding: 1rem 0;

  > .copyright {
    text-align: center;
    opacity: 0.8;
  }
}
</style>
