import type { StorefrontApiClient } from '@shopify/storefront-api-client';
import type { CartInput } from '~/types/storefront.types';

export function createCart(cartInput: CartInput) {
  const nuxtApp = useNuxtApp();

  return (nuxtApp.$ShopifyClient as StorefrontApiClient).request(
    `#graphql
      # This mutation creates a cart and returns information about the cart to ensure it's correct (id, lines, product variant id, etc) as well as some information about the cart you may want (e.g. cost, subtotalAmount, totalTaxAmount, totalDutyAmount). The checkoutUrl object contains the url of the checkout for the created cart
      mutation createCart($cartInput: CartInput) {
          cartCreate(input: $cartInput) {
              cart {
                  id
                  createdAt
                  updatedAt
                  checkoutUrl
                  lines(first: 10) {
                      edges {
                          node {
                              id
                              merchandise {
                                  ... on ProductVariant {
                                      id
                                  }
                              }
                          }
                      }
                  }
                  attributes {
                      key
                      value
                  }
                  cost {
                      totalAmount {
                          amount
                          currencyCode
                      }
                      subtotalAmount {
                          amount
                          currencyCode
                      }
                      totalTaxAmount {
                          amount
                          currencyCode
                      }
                      totalDutyAmount {
                          amount
                          currencyCode
                      }
                  }
              }
          }
      }`,
    {
      variables: {
        cartInput,
      },
    },
  );
}
