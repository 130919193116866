import {createStorefrontApiClient} from "@shopify/storefront-api-client";

export default defineNuxtPlugin({
  name: 'shopify',
  // enforce: 'pre', // or 'post'
  async setup (nuxtApp) {
    const config = useRuntimeConfig()

    const client = createStorefrontApiClient({
      storeDomain: 'http://roots-protein-powder.myshopify.com',
      apiVersion: '2024-07',
      publicAccessToken: config.public.SHOPIFY_PUBLIC_API_TOKEN,
    });

    nuxtApp.provide('ShopifyClient', client)
  }
})
