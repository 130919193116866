<template>
  <div class="loading-animation" :class="[`-${state}`]">
    <div class="logowrapper">
      <img class="logo" src="/logo.svg" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts">
const states = ['-start', 'logo', 'finished'];

const state = ref(states[0]);

async function start() {
  state.value = states[1];

  await sleep(2500);

  state.value = states[2];

  document.removeEventListener('click', start);
  document.documentElement.classList.add('-ready');

  await sleep(1000);

  document.body.style.overflowY = 'auto';
  document.body.style.maxHeight = 'unset';

  window.scrollTo({
    top: 0,
    behavior: 'instant',
  });
}

watch(
  state,
  function () {
    if (document) {
      document.documentElement.setAttribute('data-loading-state', state.value);
    }
  },
  {
    immediate: true,
  },
);

onMounted(function () {
  start();
});

function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}
</script>

<style scoped lang="scss">
.loading-animation {
  width: 100vw;
  height: 100lvh;
  position: fixed;
  inset: 0;
  z-index: 100;

  &.-text {
    > .textwrapper {
      opacity: 1;
    }
  }

  &.-logo {
    > .logowrapper {
      transform: translateY(0%);
      opacity: 1;

      > .logo {
        opacity: 1;
      }
    }
  }

  &.-finished {
    pointer-events: none;

    > .textwrapper,
    > .logowrapper {
      opacity: 0;
    }

    > .logowrapper {
      transform: translateY(0%);
    }
  }

  > .textwrapper,
  > .logowrapper {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-timing-function: ease-out;
    max-height: 100svh;
  }

  > .textwrapper {
    background-color: var(--color-black);
    color: var(--color-brown);
    opacity: 0;
    transition: opacity 2000ms;

    > .text {
      text-align: center;
      max-width: min(80vw, 70ch);

      p {
        padding: 0.5rem 0;

        &:not(.-click) {
          opacity: 0.9;
        }

        &.-click {
          opacity: 1;
        }
      }
    }
  }

  > .logowrapper {
    transform: translateY(75%);
    transition:
      transform 1000ms var(--ease-out-quint),
      opacity 1000ms;
    opacity: 0;

    > .logo {
      transition: opacity 2000ms ease-out;
      width: 50vw;
      opacity: 0;
    }
  }
}
</style>
