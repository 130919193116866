<template>
  <div class="error">
    <h1>Error Page</h1>
    <button @click="handleError()">Clear error</button>
  </div>
</template>

<script setup lang="ts">
function handleError() {
  clearError({ redirect: '/' });
}
</script>
