import type { StorefrontApiClient } from '@shopify/storefront-api-client';
import type { CartLineInput } from '~/types/storefront.types';

export function addCartLines(cartId: string, lines: [CartLineInput]) {
  const nuxtApp = useNuxtApp();

  return (nuxtApp.$ShopifyClient as StorefrontApiClient).request(
    `#graphql
      # This mutation adds lines to existing cart, returns the quantity and product id. 
      # This mutation also accepts sellingPlanId
      mutation addCartLines($cartId: ID!, $lines: [CartLineInput!]!) {
          cartLinesAdd(cartId: $cartId, lines: $lines) {
              cart {
                  id
                  lines(first: 10){
                      edges
                      {
                          node{
                              quantity
                              merchandise{
                                  ... on ProductVariant {
                                      id
                                  }
                              }
                          }
                      }
                  }
                  cost {
                      totalAmount {
                          amount
                          currencyCode
                      }
                      subtotalAmount {
                          amount
                          currencyCode
                      }
                      totalTaxAmount {
                          amount
                          currencyCode
                      }
                      totalDutyAmount {
                          amount
                          currencyCode
                      }
                  }
              }

              userErrors {
                  field
                  message
              }
          }
      }`,
    {
      variables: {
        cartId,
        lines,
      },
    },
  );
}
