import type { StorefrontApiClient } from '@shopify/storefront-api-client';
import type { CartLineUpdateInput } from '~/types/storefront.types';

export function updateCartLines(cartId: string, lines: [CartLineUpdateInput]) {
  const nuxtApp = useNuxtApp();

  return (nuxtApp.$ShopifyClient as StorefrontApiClient).request(
    `#graphql
      # This mutation is used to add a product variant of the same type to the cart. 
      # In the below example, the quantity of the variant (in variables.json) is increased and the id and quantity
      # are returned to confirm they are correct.
      mutation updateCartLines($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
          cartLinesUpdate(cartId: $cartId, lines: $lines) {
              cart {
                  id
              }
          }
      }`,
    {
      variables: {
        cartId,
        lines,
      },
    },
  );
}
