import type { StorefrontApiClient } from '@shopify/storefront-api-client';
import type { Cart } from '~/types/storefront.types';

export function queryCart(cartId: string) {
  const nuxtApp = useNuxtApp();

  return (nuxtApp.$ShopifyClient as StorefrontApiClient).request<{ cart: Cart }>(
    `#graphql
      # Query a cart by id and return some of the cart's objects. 
      # See documentation here for comprehensive list: https://shopify.dev/api/storefront/latest/queries/cart 
      query cartQuery($cartId: ID!) {
          cart(id: $cartId) {
              id
              createdAt
              updatedAt
              checkoutUrl
              lines(first: 10) {
                  edges {
                      node {
                          id
                          quantity
                          merchandise {
                              ... on ProductVariant {
                                  id
                                  title
                                  price {
                                      amount
                                      currencyCode
                                  }
                                  subtitle: metafield(namespace: "descriptors", key: "subtitle") {
                                      value
                                  }
                              }
                          }
                          attributes {
                              key
                              value
                          }
                      }
                  }
              }
              attributes {
                  key
                  value
              }
              cost {
                  totalAmount {
                      amount
                      currencyCode
                  }
                  subtotalAmount {
                      amount
                      currencyCode
                  }
                  totalTaxAmount {
                      amount
                      currencyCode
                  }
                  totalDutyAmount {
                      amount
                      currencyCode
                  }
              }
              buyerIdentity {
                  email
                  phone
                  customer {
                      id
                  }
                  countryCode
              }
          }
      }`,
    {
      variables: {
        cartId,
      },
    },
  );
}
