import type { StorefrontApiClient } from '@shopify/storefront-api-client';

export function removeCartLines(cartId: string, lineIds: string[]) {
  const nuxtApp = useNuxtApp();

  return (nuxtApp.$ShopifyClient as StorefrontApiClient).request(
    `#graphql
      # Remove lines from existing cart. Use the cost, subtotal, etc or userError 
      # message to confirm that the correct line has been removed. 
      # The userError message will let you know if the line in the request does not exist. 
      mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
          cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
              cart {
                  id
              }
              userErrors {
                  field
                  message
              }
          }
      }`,
    {
      variables: {
        lineIds,
        cartId,
      },
    },
  );
}
